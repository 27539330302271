import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '../../components/Section'
import Navigation from '../../components/Navigation'
import useSiteMetadata from '../../helpers-blog/useSiteMetadata'
import attachSocialIcons from '../../attachSocialIcons'

const Social = () => {
  const { social } = useSiteMetadata()

  return (
    <Section aside title="">
      <Card variant='paper'>
        <Navigation items={attachSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  )
}

export default Social
